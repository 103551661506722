
import { defineComponent, PropType } from "vue";

const CFormSelect = defineComponent({
  name: "CFormSelect",
  emits: ["update:modelValue"],
  props: {
    elementID: { type: String, required: true },
    options: { type: Array as PropType<FormSelectOption[]>, required: true },
    placeholder: { type: String, default: "Selecione" },
    isLoading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    width: { type: String, default: "24rem" },
    modelValue: { required: true },
  },
  setup(_, { emit }) {
    function handleSelectOption(event: { target: { value: any } } & Event) {
      emit("update:modelValue", event.target.value === "null" ? null : event.target.value || null);
    }

    return { handleSelectOption };
  },
});

export type FormSelectOption<V = any> = { text: string; value: V };

export default CFormSelect;
