
import { computed, defineComponent, reactive, ref } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";
import CFormSelect from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormInputNumber from "@/components/form/InputNumber.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import PInputSwitch from "primevue/inputswitch";
import PMultiSelect from "primevue/multiselect";
import PDropdown from "primevue/dropdown";
import PBreadcrumb from "primevue/breadcrumb";
import PProgressBar from "primevue/progressbar";

import { agendaTypeOptions, genderOptions } from "@/libs/utils";
import { AgendaForm, AgendeType } from "@/store/agenda/types";

const VAgendaForm = defineComponent({
  name: "VAgendaForm",
  components: {
    CModal,
    ...{ CFormSelect, CFormInputText, CFormInputNumber, CFormTextarea },
    ...{ PInputSwitch, PMultiSelect, PDropdown, PBreadcrumb, PProgressBar },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const _id = Number(route.params._id);
    const isEditForm = route.name?.toString().includes("-edit");

    const modules: { [key: string]: { label: string; value: AgendeType } } = {
      consultation: { label: "Consulta", value: "C" },
      exam: { label: "Exame de imagem", value: "E" },
      laboratory: { label: "Exame laboratorial", value: "L" },
      procedure: { label: "Procedimento", value: "P" },
      service: { label: "Pacote de serviços", value: "PS" },
    };
    const currentModuleName = String(route.name?.toString().split("-")[1]);
    const currentModule = modules[currentModuleName];

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} agenda de ${currentModule.label} | obmed` });
    if (!isEditForm) store.commit("setAgenda", null);

    const agenda = computed(() => store.state.agenda.current);
    const procedures = computed(() => store.state.procedure.establishment);
    const specialists = computed(() => store.state.specialist.all);
    const specialties = computed(() => store.state.specialty.establishment);

    const breadcrumb = [
      { label: "Atendimento", icon: "fas fa-hand-holding-medical mr-1", to: { name: "root" } },
      { label: "Agendas", icon: "fas fa-calendar-alt mr-1", to: { name: "agenda-list" } },
      isEditForm
        ? { icon: "fas fa-edit mr-1", label: "Editar", to: { name: route.name } }
        : { icon: "fas fa-plus-circle mr-1", label: "Cadastrar", to: { name: route.name } },
    ];

    const loading = reactive({ agenda: false, procedure: false, specialist: false, specialties: false, submit: false });

    function generateForm(): AgendaForm {
      return {
        ie_tipo_agenda: agenda.value?.ie_tipo_agenda || currentModule.value || null,
        cd_especialista: agenda.value?.cd_especialista || null,
        cd_especialidade: agenda.value?.cd_especialidade || null,
        cd_especialidades: agenda.value?.cd_especialidades?.map((item) => item.cd_especialidade) || [],
        cd_procedimento_estabelecimento: agenda.value?.cd_procedimento_estabelecimento?.id || null,
        ie_hora_marcada: agenda.value?.ie_hora_marcada ?? false,
        ie_agenda_presencial: agenda.value?.ie_agenda_presencial ?? true,
        ie_status: agenda.value?.ie_status ?? true,
        ie_sexo: agenda.value?.ie_sexo || "N",
        nr_idade_minima: agenda.value?.nr_idade_minima || 0,
        nr_idade_maxima: agenda.value?.nr_idade_maxima || 120,
        ds_observacao: agenda.value?.ds_observacao || "",
        qtd_vaga: agenda.value?.qtd_vaga || 0,
        cd_procedimentos: agenda.value?.cd_procedimentos?.map((item) => item?.cd_procedimento_estabelecimento) || [],
        cd_estabelecimento: agenda.value?.cd_estabelecimento || Number(localStorage.getItem("CD_ESTABELECIMENTO")) || 0,
        ie_telemedicina: agenda.value?.ie_telemedicina ?? false,
        ie_ligar_para_agenda: agenda.value?.ie_ligar_para_agenda ?? false,
        ie_sessoes_semanais: agenda.value?.ie_sessoes_semanais ?? false,
      };
    }
    const form = ref<AgendaForm>(generateForm());
    const scheduleType = [
      { label: "Presencial", value: false },
      { label: "Virtual", value: true },
    ];

    async function getSpecialists() {
      loading.specialist = true;
      await store.dispatch("getAllSpecialists");
      loading.specialist = false;
    }

    async function getEstablishmentSpecialty() {
      loading.specialties = true;
      await store.dispatch("getEstablishmentSpecialty", { ie_tipo: "C" });
      loading.specialties = false;
    }

    async function getProcedure() {
      loading.procedure = true;
      await store.dispatch("getEstablishmentProcedure", {
        ie_grupo: form.value.ie_tipo_agenda === "E" ? "I" : form.value.ie_tipo_agenda || "C",
        // show: true,
      });
      loading.procedure = false;
    }

    async function getAgenda() {
      loading.agenda = true;
      await store.dispatch("getAgenda", { _id });
      loading.agenda = false;

      form.value = generateForm();
    }

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      function showInvalidField(fieldName: string) {
        store.commit("addToast", { summary: `O campo "${fieldName}" é obrigatório!`, severity: "warn" });
      }

      if (["C", "PS"].includes(form.value.ie_tipo_agenda || "")) {
        if (!form.value.cd_procedimento_estabelecimento) return showInvalidField("Procedimento");
        if (!form.value.cd_especialista) return showInvalidField("Especialista");
        if (!form.value.cd_especialidades?.length) return showInvalidField("Especialidades");

        form.value.cd_especialidade = form.value.cd_especialidades[0] || null;
        form.value.cd_procedimentos = undefined;
      } else {
        if (!form.value.cd_procedimentos?.length) {
          if (form.value.ie_tipo_agenda === "E") return showInvalidField("Exame de imagem");
          if (form.value.ie_tipo_agenda === "L") return showInvalidField("Exame laboratorial");

          return showInvalidField("Procedimento");
        }

        form.value.cd_especialista = undefined;
        form.value.cd_especialidade = undefined;
        form.value.cd_procedimento_estabelecimento = undefined;
      }

      form.value.ie_telemedicina = (form.value.ie_telemedicina as any) === "true";

      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updateAgenda", { _id, form: form.value })
        : await store.dispatch("createAgenda", { form: form.value });
      loading.submit = false;

      if (response?.status === 200) {
        store.dispatch("getAgendas", { ie_tipo_agenda: currentModule.value });
        handleClose();
      }
    }

    if (isEditForm) getAgenda();

    if (["C", "PS"].includes(currentModule.value)) {
      getSpecialists();
      getEstablishmentSpecialty();
    }

    getProcedure();

    return {
      ...{ agenda, procedures, form, specialists, specialties, scheduleType },
      ...{ breadcrumb, isEditForm, agendaTypeOptions, genderOptions, currentModule, loading },
      ...{ onSubmit, handleClose },
    };
  },
});

export default VAgendaForm;
